<template>
  <div class="msg-wrap" v-if="flag">
    <div class="msg">{{ msgText }}</div>
  </div>
</template>
<script>
 export default {
  data() {
    return {
      flag: false,
      msgText: '当前已是最后一条'
    }
  },
  methods: {
    show(text) {
      if (text) this.msgText = text;
      this.flag = true;
      setTimeout(() => {
        this.flag = false;
      }, 2000)
    },
  }
 }
</script>
<style lang="less" scoped>
.msg-wrap {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2200;
}
.msg {
  width: 400px;
  height: 96px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.6);
  color: #FFFFFF;
  text-align: center;
  line-height: 96px;
  font-size: 20px;
  margin-top: -100px;
}
</style>