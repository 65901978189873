<template>
    <div class="footer">
        <div class="footerInfo">
            <div class="nav">
                <h4>网站导航</h4>
                <ul>
                    <li>招商加盟</li>
                    <li>景区资讯</li>
                    <li>皓月牧场</li>
                    <li>活动纪实</li>
                    <li>皓月文旅</li>
                    <li>领导支持</li>
                    <li>产品中心</li>
                    <li>诚聘英才</li>
                </ul>
            </div>
            <div class="nav">
                <h4>关于我们</h4>
                <ul>
                    <li>企业简介</li>
                    <li>企业荣誉</li>
                    <li>皓月人文</li>
                    <li>大事纪实</li>
                </ul>
            </div>
            <div class="nav">
                <h4>联系我们</h4>
                <div>
                    <p>0431-84456971</p>
                    <p>邮编：130013</p>
                    <p>邮箱：china_haoyue@163.com</p>
                    <p>吉林省长春市绿园区皓月大路11111号</p>
                </div>
            </div>
            <div class="nav">
                <h4 class="ydq">关注我们</h4>
                <div class="code ydq">

                </div>
                <p class="weixi ydq">皓月官方微信</p>
            </div>
        </div>
        <div class="copyRight">
          <p>吉ICP备14004422号1 © 2020 吉林省长春皓月清真肉业股份有限公司</p>
        </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  
  
  export default {
    name: 'HomeView',
    components: {
      
    }
  }
  </script>
  
  <style lang="less" scoped>
  .footer{
    width: 100%;
    height: 430px;
    background: #FF7E14;
    margin: 0 auto;
    padding: 72px 0;
    box-sizing: border-box;
    position: relative;
    z-index: 3;
  }
  .footerInfo{
    width: 1320px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
  }
  .nav {
    width: 25%;
    text-align: left;
  }
  .nav h4{
    font-size: 24px;
    color: #ffffff;
  }
  .nav ul {
    width: 180px;
    list-style-type: none;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  .nav ul li {
    width: 90px !important;
    margin: 0 0 16px 0;
    font-size: 18px;
    color: #ffffff;
  }
  .nav div p {
    font-size: 18px;
    color: #ffffff;
  }
  .code{
    width: 120px;
    height: 120px;
   
    background: #6F6F6F;
    border: 6px solid #ffffff;
  }
  .weixi{
    font-size: 18px;
    color: #ffffff;
    margin: 10px 0 0 10px;
  }
  .ydq{
    margin-left: 156px;
  }
  .copyRight{
    width: 100%;
    height: 64px;
    background: #E67112;
    text-align: center;
    padding: 30px auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .copyRight p{
    font-size: 18px;
    color: #ffffff;
  }
  </style>