import request from "@/utils/request";

// 获取合作伙伴
export const getBuddy = () => {
  return request({
    url: "/travel-interface/clientPartner/list",
    method: "get",
  });
};

// 获取活动资讯接口
export const getConsult = () => {
  return request({
    url: "/travel-interface/pc/topActList",
    method: "get",
  });
};

// 获取资讯分类接口
export const activityClassify = () => {
  return request({
    url: "/travel-interface/pc/actTypeList",
    method: "get",
  });
};

// 获取研学娱乐数据
export const getStudy = () => {
  return request({
    url: "/travel-interface/pc/homeDataParam",
    method: "get",
  });
};

// 获取研学娱乐列表
export const getStudyList = (data) => {
  return request({
    url: "/travel-interface/pc/pclist",
    method: "get",
    params: data,
  });
};

// 获取研学娱乐详情
export const getStudyDetail = (data) => {
  return request({
    url: "/travel-interface/pc/pclistDetail",
    method: "get",
    params: data,
  });
};

// 获取活动资讯列表
export const getActList = (data) => {
  return request({
    url: "/travel-interface/pc/actList",
    method: "get",
    params: data,
  });
};

// 获取咨询电话
export const getTelList = () => {
  return request({
    url: "/travel-interface/pc/homeContact",
    method: "get",
  });
};

// 获取继续浏览
export const getBrowse = (data) => {
  return request({
    url: "/travel-interface/pc/scan",
    method: "get",
    params: data,
  });
};

// 获取景点继续浏览
export const getScenicBrowse = (data) => {
  return request({
    url: "/travel-interface/clientScenic/scan",
    method: "get",
    params: data,
  });
};

// 获取资讯详情
export const getActDetail = (data) => {
  return request({
    url: "/travel-interface/pc/actDetail",
    method: "get",
    params: data,
  });
};

// 首页点击景点更多接口
export const getHomePageMoreList = () => {
  return request({
    url: "/travel-interface/clientScenic/homePageMoreList",
    method: "get",
  });
};

// 获取首页景点列表
export const getScenicList = () => {
  return request({
    url: "/travel-interface/clientScenic/homePageList",
    method: "get",
  });
};

// 提交意见反馈
export const feedbackAdd = (data) => {
  return request({
    url: "/travel-interface/pc/feedbackAdd",
    method: "post",
    data: data,
  });
};
// 查看景点详情
export const getScenicInfoData = (data) => {
  return request({
    url: "/travel-interface/clientScenic/scenicInfoData",
    method: "get",
    params: data,
  });
};

// 查询菜单
export const queryMenu = () => {
  return request({
    url: "/travel-interface/pc/navigationList",
    method: "get",
  });
};

// 网站首页轮播图
export const querySlider = () => {
  return request({
    url: "/travel-interface/clientCarousel/pageCarouselList",
    method: "get",
  });
};

// 获取研学娱乐下一个
export const getStudyNext = (data) => {
  return request({
    url: "/travel-interface/pc/pclistDetailNext",
    method: "get",
    params: data,
  });
};

// 获取轮播详情
export const getCarouselInfo = (data) => {
  return request({
    url: "/travel-interface/clientCarousel/pageCarouselInfo",
    method: "get",
    params: data,
  });
};


// 获取是否显示灰色滤镜
export const getFilter = () => {
  return request({
    url: "/travel-interface/app/pageWhiteType",
    method: "get"
  });
};
