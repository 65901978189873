<template>
  <div class="activity-item">
    <div class="pic-wrap" @click="detail">
      <img :src="item.thumbFileList[0].filePath" class="pic" v-if="item.thumbFileList && item.thumbFileList[0]" />
    </div>
    <div class="name" @click="detail">{{ item.actTitle }}</div>
    <div class="time">{{ queryTime(item.updateTime || item.createTime) }}</div>
  </div>
</template>
 <script>
 export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    openType:{
      type: String,
      default: () => ""
    }
  },
  methods: {
    detail() {
      
      if(this.openType == "blank"){
        const url = this.$router.resolve({ path: `/activityDetail?id=${this.item.id}`}).href;
        window.open(url, '_blank');
      }else{
        this.$router.push(`/activityDetail?id=${this.item.id}`);
      }
    },
    queryTime(time) {
      let t = '--';
      if (time) t = time.split(' ')[0];
      return t;
    }
  }
 }
</script>
<style lang="less" scoped>
.activity-item {
  width: 424px;
  height: 366px;
  background: #FFFFFF;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 24px;
  .pic-wrap {
    width: 424px;
    height: 238px;
    overflow: hidden;
  }
  .pic {
    width: 424px;
    height: 238px;
    display: block;
    transition: all .1s linear;
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
    }
  }
  .name {
    font-family: SourceHanSansCN;
    font-size: 20px;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: 0em;
    font-variation-settings: "opsz" auto;
    color: #343434;
    text-align: left;
    height: 60px;
    width: 376px;
    margin: 16px auto 4px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
  .time {
    font-family: SourceHanSansCN;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0em;
    font-variation-settings: "opsz" auto;
    color: #9E9E9E;
    text-align: left;
    margin-left: 24px;
  }
}
</style>